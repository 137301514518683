import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Press 4×4\\@80% 1RM`}</p>
    <p>{`Bar Muscle Ups 4×4 (scale to CTB or regular pullups)`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9:`}</p>
    <p>{`Ring Dips`}</p>
    <p>{`CTB Pullups`}</p>
    <p>{`Calorie Row`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      